@import url(https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
:root {
  --black: #212121;
  --white: #fafafa;
  --purple-section: #f6ddf9;
  --bgcolor: #dadada;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Cabin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #dadada;
  background: var(--bgcolor);
}

body #root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

section {
  color: #212121;
  color: var(--black);
  background: #f6ddf9;
  background: var(--purple-section);
  font-size: 1.25em;
  line-height: 1.5;
}

section:nth-child(odd) {
  background: #fafafa;
  background: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

